import React from "react";
import Nav from "./Navbar/Navbar";
// import HeaderMid from "./HeaderMid/HeaderMid";

const Header = () => {
  return (
    <div>
      <Nav />
      {/* <HeaderMid/> */}
    </div>
  );
};

export default Header;
