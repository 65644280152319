import React from "react";
import HeaderMid from "../Header/HeaderMid/HeaderMid";
import Cards from "../Cards/Cards";
import { useTokens } from "../../store/tokens/hook";
// import { useNavigate } from "react-router-dom";
const Dashboard = () => {
  let tokenData = useTokens();
  tokenData = Object.values(tokenData).filter((item, index) => {
    return index !== 15 && index !== 18 && index !== 19;
  });

  // console.log(tokenData);

  // const navigate=useNavigate()

  return (
    <div>
      <HeaderMid />
      <Cards data={tokenData} />
    </div>
  );
};

export default Dashboard;
