export const tokenContracts = {
  56: "0xdcf06C87CfDf28f3Ee58d6b0b60646cf89527140", // bnb
  137: "0x8b9c8CaDe55FEB0479602910Ef76F1B070dDf4a3", // polygon
  56288: "0x24E72C50E4a4f9745A04568922e936365Bf32675", // boba
  25: "0xd03e6FcF68e59fDCc350a6A1c06d2Bda10EC7f77", // cronos
  82: "0xA558CFEC30C0fB0f0C97f74278A6E2453A3f5cF1", // meter
  66: "0x9aB28C407Bdb5f63D856DDA2E932B0DFd2279Fa5", // okx
  40: "0x24E72C50E4a4f9745A04568922e936365Bf32675", // telos
  8082: "0x5aa5e0C7731e7D086D4272e2e73606A0f92E74d2", // shardeum
};

export const marketplaceContracts = {
  56: "0x32dc9aad6bf179C02379E3b93E69c036B10Bb0Ae",
  137: "0x4962715e0B7158665A80e99c9508F513A0d3e500",
  56288: "0xA0f4FF4808CAdf029009A51f3AF1Bb0cBE283C72",
  25: "0xfEEfDca2FfACc52f7148004620393FD5B94b36dF",
  82: "0xc7A3AA001517Ff56C8644b206b1d389Cd483a67d",
  66: "0x3F70B465140bd698438f1F48c5c743Ce0709D63A",
  40: "0x3F70B465140bd698438f1F48c5c743Ce0709D63A",
  8082: "0x78FC266B21515F3B011A61d77d8AaF1fF7D9259D", // shardeum
};

export const mtrgAddress = "0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3";
